import { Divider, Flex } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { allRoutes } from "@/router";
import { getAccessibleRoutes } from "@/utils/routeUtils";
import styled from "styled-components";
import SvgIcon from "@/components/SvgIcon";
import { useSelector } from "react-redux";

const RawBoSidebar = ({ className, isExpanded }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const userRoles = useSelector((state) => state.auth?.userRoles);

  const [hoveringKey, setHoveringKey] = useState(null);

  const getRoutesByGroupingType = (groupingType) => {
    const routesForSidebar = getAccessibleRoutes(allRoutes, userRoles);

    return routesForSidebar.filter(
      ({ sidebarProps }) => sidebarProps.groupingType === groupingType
    );
  };

  const getNavItem = ({ key, iconSrc, label, link }) => {
    const currentPath = location.pathname;
    const rootLink = `/${link.split("/")[1]}`;
    const isActiveItem = currentPath.startsWith(rootLink);

    let iconSrcToUse = iconSrc;

    return (
      <a
        key={key}
        className="nav-items"
        href={link}
        rel="noopener noreferrer"
        onMouseEnter={() => setHoveringKey(key)}
        onMouseLeave={() => setHoveringKey(null)}
      >
        <Flex className="nav-item" justify="center" align="center">
          {(isActiveItem || hoveringKey === key) && (
            <div className="nav-border"></div>
          )}

          <div className="nav-icon-container">
            <SvgIcon
              src={
                isActiveItem || hoveringKey === key
                  ? `${iconSrcToUse}-active`
                  : iconSrcToUse
              }
            />
          </div>
        </Flex>

        {isExpanded && (
          <p
            className={`nav-label delayed-fade-in ${isActiveItem || hoveringKey === key ? "active" : ""}`}
          >
            {t(label)}
          </p>
        )}
      </a>
    );
  };

  return (
    <div className={className}>
      <a
        className={`logo-wrapper ${isExpanded ? "expanded" : ""}`}
        href="/"
        rel="noopener noreferrer"
        onClick={(e) => {
          if (userRoles.includes("USER")) {
            e.preventDefault();
          }
        }}
      >
        <div className={isExpanded ? "logo-icon-expanded" : ""}>
          <SvgIcon width="40" height="40" src="logo" />
        </div>
        {isExpanded && <p className="logo-label">{t("general.port_comm")}</p>}
      </a>

      <div className="nav-container">
        <div className="nav-item-container">
          {getRoutesByGroupingType("TOOLS").map(({ sidebarProps }) =>
            getNavItem(sidebarProps)
          )}
        </div>

        {getRoutesByGroupingType("SETTINGS").length > 0 && (
          <>
            <Divider className="nav-divider" />
            <div className="nav-title">{t("module_title.settings")}</div>
            <div className="nav-item-container">
              {getRoutesByGroupingType("SETTINGS").map(({ sidebarProps }) =>
                getNavItem(sidebarProps)
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const BoSidebar = styled(RawBoSidebar)`
  z-index: 100;
  position: fixed;
  width: 4.5rem;
  height: 100vh;
  background-color: var(--bo-sidebar-color);
  border-right: 0.063rem solid var(--default-border-color);
  color: var(--bo-sidebar-font-color);
  transition: width 0.6s ease-in-out;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }

  .nav-title {
    font-family: var(--poppins-medium-font);
    font-weight: 500;
    font-size: var(--font-size-xs);
    line-height: 1.25rem;
    color: var(--bo-sidebar-font-color);
    padding-left: 0.688rem;
    padding-bottom: 0.25rem;
  }

  :hover {
    width: 18.19rem;

    .nav-title {
      padding-left: 1.5rem;
    }
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    padding: 1.5rem 1rem;

    &.expanded {
      padding: 1.5rem 1.25rem;
    }

    .logo-icon-expanded {
      padding-right: 0.688rem;
    }

    .logo-label {
      color: var(--default-font-color);
      font-weight: 600;
      font-size: 1.25rem;
      font-family: var(--poppins-semi-bold-font);
    }

    :hover {
      cursor: pointer;
    }
  }

  .nav-container {
    padding-bottom: 1.5rem;
  }

  .nav-divider {
    background-color: #e2e8f01a;
  }

  .nav-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.25rem;

    :last-child {
      margin-bottom: 0;
    }

    :hover {
      cursor: pointer;
    }

    .nav-item {
      width: 3.5rem;
      height: 2.75rem;
      position: relative;

      .nav-border {
        position: absolute;
        left: 0;
        height: 2.188rem;
        border-radius: 0 0.5rem 0.5rem 0;
        border-left: 0.25rem solid #a9dfd8;
      }

      .nav-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.25rem;
        height: 1.25rem;
        padding-left: 0.938rem;
      }
    }

    .nav-label {
      font-family: var(--poppins-medium-font);
      font-weight: 500;
      font-size: var(--font-size-s);
      line-height: 1.25rem;
      padding-left: 1px;

      &.active {
        color: var(--primary-color);
      }
    }

    .delayed-fade-in {
      opacity: 0;
      animation: fadeIn 0.5s ease-in forwards;
      animation-delay: 0.2s;
    }

    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }
  }
`;

export default BoSidebar;

import { protectedRoute } from "@/utils/routeUtils";

const moduleTitle = "module_title.human_resource_management";
const allowedRoles = ["SUPER_ADMIN", "MANAGER", "OFFICER"];

const humanResourceRoutes = {
  path: "human-resource",
  title: moduleTitle,
  sidebarProps: {
    key: "1-6",
    iconSrc: "human-resource",
    label: "module_title.human_resource",
    link: "/human-resource",
    groupingType: "TOOLS",
    order: 6,
    permissions: allowedRoles,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: protectedRoute(
        [() => import("@/pages/human-resource/HumanResourceList")],
        allowedRoles
      ),
    },
    {
      path: ":id",
      title: moduleTitle,
      headerProps: {
        backUrl: "/human-resource",
      },
      element: protectedRoute(
        [() => import("@/pages/human-resource/HumanResourceDetails")],
        allowedRoles
      ),
    },
  ],
};

export default humanResourceRoutes;

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "@/store/slices/auth";
import { DEFAULT_TIMEOUT, DEFAULT_LANG } from "@/constants";
import { App } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ls from "localstorage-slim";
import { getAccessibleRoutes } from "./routeUtils";
import { allRoutes } from "@/router";

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: DEFAULT_TIMEOUT, // request timeout
  headers: {
    "Content-Type": "application/json",
    App: "false",
  },
};

// for client side
export const useApi = () => {
  const { notification } = App.useApp();
  const { t } = useTranslation();
  const token = useSelector((state) => state.auth?.token);
  const userRoles = useSelector((state) => state.auth?.userRoles);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // create an axios instance
  const service = axios.create(config);
  const serviceWithoutAxiosError = axios.create(config);

  let errorMsgDisplayedOnce = [];
  const hiddenErrorCodeMsg = ["request.error.token.invalid"];

  // request interceptor
  const reqSuccessInterceptor = (serviceConfig) => {
    let locale = ls.get("locale") || DEFAULT_LANG;

    serviceConfig.headers.locale = locale;

    if (token) {
      serviceConfig.headers.Authorization = "Bearer " + token;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }

    return serviceConfig;
  };
  const reqErrorInterceptor = (error) => {
    // do something with request error
    console.error(error); // for debug
    Promise.reject(error);
  };
  service.interceptors.request.use(reqSuccessInterceptor, reqErrorInterceptor);
  serviceWithoutAxiosError.interceptors.request.use(reqSuccessInterceptor);

  // response interceptor
  const resSuccessInterceptor = (response) => {
    const res = response;
    const errorCode = res.data.errorCode;
    const errorMsg =
      res.data.warningMessage || res.data.errorMessage || t("message.error");
    // console.log(res)
    if (res.status === 200 && res.data.status === "ok") {
      return res.data;
    } else {
      console.error(response); // for debug
      if (
        errorCode === "request.error.token.invalid" ||
        errorCode === "request.error.user.not.found"
      ) {
        // Already logged out, no need ask user to log out again
        if (res.config.url === "/user/logout") return;

        dispatch(authActions.logout());
        navigate("/sign-in");
        notification.error({
          message: t("notification.logout"),
        });
      }
      if (errorCode === "request.error.no.access" && userRoles?.length) {
        window.location.reload();

        const allAccessibleRoutes = getAccessibleRoutes(allRoutes, userRoles);
        const firstAccessibleRoute = `/${allAccessibleRoutes[0].path}`;

        navigate(firstAccessibleRoute);
      }
      if (
        errorMsgDisplayedOnce.length === 0 ||
        errorMsgDisplayedOnce.find((el) => el === errorMsg) === null
      ) {
        // only display once for each errorMsg
        if (!hiddenErrorCodeMsg.find((el) => el === errorCode)) {
          notification.error({
            message: errorMsg,
          });
        }
      }
      errorMsgDisplayedOnce.push(errorMsg);
      setTimeout(() => {
        errorMsgDisplayedOnce = errorMsgDisplayedOnce.filter(
          (el) => el !== errorMsg
        );
      }, 1000);
      return res.data;
    }
  };

  const resFailedInterceptor = (error) => {
    console.error(error); // for debug
    if (error.response?.status === 401) {
      // do nothing
    } else {
      notification.error({
        message: error.message,
      });
      return Promise.reject(error);
    }
  };

  service.interceptors.response.use(
    resSuccessInterceptor,
    resFailedInterceptor
  );
  serviceWithoutAxiosError.interceptors.response.use(resSuccessInterceptor);

  // NOTE: POST must have data (even it is empty) to avoid Content-Type being deleted (HTTP error 415)

  return [service, serviceWithoutAxiosError];
};

// for server side
// NO hooks available here
export const getStaticApi = () => {
  // create an axios instance
  const service = axios.create(config);
  const serviceWithoutAxiosError = axios.create(config);

  // request interceptor
  const reqSuccessInterceptor = (serviceConfig) => {
    delete axios.defaults.headers.common["Authorization"];
    return serviceConfig;
  };
  const reqErrorInterceptor = (error) => {
    // do something with request error
    console.error(error); // for debug
    Promise.reject(error);
  };
  service.interceptors.request.use(reqSuccessInterceptor, reqErrorInterceptor);
  serviceWithoutAxiosError.interceptors.request.use(reqSuccessInterceptor);

  // response interceptor
  const resSuccessInterceptor = (response) => {
    const res = response;
    // console.log(res)
    if (res.status === 200 && res.data.status === "ok") {
      return res.data;
    } else {
      console.error(response); // for debug
      return res.data;
    }
  };

  const resFailedInterceptor = (error) => {
    console.error(error); // for debug
    if (error.response?.status === 401) {
      // do nothing
    } else {
      return Promise.reject(error);
    }
  };

  service.interceptors.response.use(
    resSuccessInterceptor,
    resFailedInterceptor
  );
  serviceWithoutAxiosError.interceptors.response.use(resSuccessInterceptor);

  // NOTE: POST must have data (even it is empty) to avoid Content-Type being deleted (HTTP error 415)

  return [service, serviceWithoutAxiosError];
};

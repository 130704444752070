import SignIn from "@/pages/sign-in";
import PublicLayout from "@/layouts/PublicLayout";
import RouteGuard from "../components/RouteGuard";

const signInRoute = {
  path: "/sign-in",
  element: (
    <PublicLayout>
      <RouteGuard mustNotAuthenticated>
        <SignIn />
      </RouteGuard>
    </PublicLayout>
  ),
};

export default signInRoute;

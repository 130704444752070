import BoLayout from "@/layouts/BoLayout";
import LazyLoadWrapper from "@/router/components/LazyLoadWrapper";
import RequiredAuth from "@/router/components/RequiredAuth";
import RouteGuard from "@/router/components/RouteGuard";

export const getAccessibleRoutes = (allRoutes, userRoles) => {
  const accessibleRoutes = allRoutes.filter(
    ({ sidebarProps }) =>
      !!sidebarProps?.key &&
      sidebarProps?.permissions.some((permission) =>
        userRoles.includes(permission)
      )
  );

  return accessibleRoutes.sort((a, b) => {
    return (a.sidebarProps?.order || 0) - (b.sidebarProps?.order || 0);
  });
};

export const protectedRoute = (importFns, allowedRoles) => {
  const createNestedComponent = (importFns, allowedRoles) =>
    importFns.reduceRight(
      (acc, curr) => (
        <RequiredAuth allowedRoles={allowedRoles}>
          <LazyLoadWrapper importFn={curr}>{acc}</LazyLoadWrapper>
        </RequiredAuth>
      ),
      null
    );

  return (
    <BoLayout>
      <RouteGuard mustAuthenticated>
        {createNestedComponent(importFns, allowedRoles)}
      </RouteGuard>
    </BoLayout>
  );
};

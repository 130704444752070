import { protectedRoute } from "@/utils/routeUtils";
import RedirectToCompanyDetails from "../components/RedirectToCompanyDetails";

const moduleTitle = "module_title.company_management";
const allowedRoles = ["SUPER_ADMIN", "MANAGER", "OFFICER"];

const companyRoutes = {
  path: "company",
  title: moduleTitle,
  sidebarProps: {
    key: "1-5",
    iconSrc: "company",
    label: "module_title.company",
    link: "/company",
    groupingType: "TOOLS",
    order: 5,
    permissions: allowedRoles,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      element: protectedRoute(
        [() => import("@/pages/company/CompanyList")],
        allowedRoles
      ),
    },
    {
      path: "CompanyList",
      title: moduleTitle,
      element: protectedRoute(
        [() => import("@/pages/company/CompanyList")],
        allowedRoles
      ),
    },
    {
      path: ":id",
      title: moduleTitle,
      children: [
        {
          index: true,
          path: "",
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: <RedirectToCompanyDetails />,
        },
        {
          path: "company-details",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: protectedRoute(
            [
              () => import("@/pages/company/CompanyInfo"),
              () => import("@/pages/company/company-details/CompanyDetails"),
            ],
            allowedRoles
          ),
        },
        {
          path: "employee",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: protectedRoute(
            [
              () => import("@/pages/company/CompanyInfo"),
              () => import("@/pages/company/employee/CompanyEmployeeList"),
            ],
            allowedRoles
          ),
        },
        {
          path: "office-rental",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: protectedRoute(
            [
              () => import("@/pages/company/CompanyInfo"),
              () =>
                import("@/pages/company/office-rental/CompanyOfficeRentalList"),
            ],
            allowedRoles
          ),
        },
        {
          path: "accommodation-rental",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: protectedRoute(
            [
              () => import("@/pages/company/CompanyInfo"),
              () =>
                import(
                  "@/pages/company/accommodation-rental/CompanyAccommRentalList"
                ),
            ],
            allowedRoles
          ),
        },
        {
          path: "permit",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: protectedRoute(
            [
              () => import("@/pages/company/CompanyInfo"),
              () => import("@/pages/company/permit/CompanyPermitList"),
            ],
            allowedRoles
          ),
        },
        {
          path: "telco-services",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: protectedRoute(
            [
              () => import("@/pages/company/CompanyInfo"),
              () =>
                import(
                  "@/pages/company/telco-services/CompanyTelcoServicesList"
                ),
            ],
            allowedRoles
          ),
        },
        {
          path: "facilities",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: protectedRoute(
            [
              () => import("@/pages/company/CompanyInfo"),
              () => import("@/pages/company/facilities/CompanyFacilitiesList"),
            ],
            allowedRoles
          ),
        },
        {
          path: "utilities",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: protectedRoute(
            [
              () => import("@/pages/company/CompanyInfo"),
              () => import("@/pages/company/utilities/CompanyUtilitiesList"),
            ],
            allowedRoles
          ),
        },
        {
          path: "commission-calculation",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: protectedRoute(
            [
              () => import("@/pages/company/CompanyInfo"),
              () =>
                import(
                  "@/pages/company/commission-calculation/CompanyCommissionCalculation"
                ),
            ],
            allowedRoles
          ),
        },
      ],
    },
  ],
};

export default companyRoutes;
